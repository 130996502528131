const Footer = () => {
  return (
    <div className="w-full relative" id="footer">
      <div className="w-full bg-[#3089f6] h-40 md:h-20 flex justify-between px-2 items-center md:px-10">
        <div className=" text-white font-semibold tracking-wide text-[16px] md:text-[12px] grid grid-cols-1 md:grid-cols-2 gap-x-4">
          <p className="font-extrabold underline">Contacto: </p>
          <p>Carrera 25 #20-36</p>
          <p>Tel: 3216369274</p>
          <p>Barrio San José</p>
          <p>Email: contacto@egavam.com</p>

          <p>Armenia, Quindío - (Colombia)</p>
        </div>
        {/* <a
          className="text-white text-xl font-extrabold tracking-widest hidden md:block"
          href="#container-products"
        >
          Ir al inicio
        </a> */}
        {/* <a
          className="block md:hidden bg-gray-200 text-[#3089f6] font-light px-4 py-1 rounded-full cursor-pointer absolute right-1 top-2 text-[20px]"
          href="#container-products"
        >
          Ir al inicio
        </a> */}
      </div>
    </div>
  );
};

export default Footer;
