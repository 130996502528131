import silicona from "../assets/products/silicona.png";
import siliconaNeutra from "../assets/products/silicona-neutra.png";
import siliconaCaja from "../assets/products/silicona-caja.png";
import siliconaNeutraCaja from "../assets/products/silicona-neutra-caja.png";
import kitDiviAcero from "../assets/products/accesorios-acero.jpeg";
import burbujaSeisMM from "../assets/products/burbuja6.png";
import vidrioTemplado from "../assets/products/vidrio-templado.png";

const productsInfoDetails = () => {
  // Emulate response server with the products
  const productsInfo = {
    products: [
      {
        name: "Silicona Multiusos",
        price: "7.500",
        pastPrice: "9.500",
        description:
          "Este modelo es de color transparente, lo que lo hace ideal para trabajos que requieran discreción y limpieza en el acabado. Como sellador, su principal componente es la silicona, conocida por su resistencia y durabilidad. Este producto es útil para el sellado de acristalamientos y aplicaciones al aire libre, en soportes no porosos, como vidrio, piezas cerámicas vitrificadas, aluminio, entre otras; Entre otras características, este producto cuenta con propiedades antihongos, lo que lo hace ideal para lugares con alta humedad o propensos a la formación de moho.",
        shortDescription: "Silicona multiusos de alto rendimiento",
        image: silicona,
        id: 1,
        stars: 5,
        specifications: [
          "Marca: EGA VAM",
          "Color: Transparente",
          "Volumen de unidad: 280 ml",
          "Formato venta: Unidad",
          "Modelo: Silicona multiusos",
        ],
      },
      {
        name: "Silicona Neutra",
        price: "9.500",
        pastPrice: "19.500",
        description:
          "Silicona Neutra es un sellador de silicona neutra de alta calidad y excelente adherencia sobre la mayoría de los materiales de construcción, porosos y no porosos ladrillo, concreto, vidrio, aluminio, cerámica, PVC, acrílico, policarbonato y madera, para el uso en interiores y exteriores. La fórmula neutra de este tipo de silicona significa que es menos propensa a causar corrosión en metales o dañar otras superficies sensibles, como el vidrio o piedras naturales, lo que es especialmente importante en la instalación de vidrios templados o laminados.",
        shortDescription: "Silicona neutra para trabajo pesado",
        image: siliconaNeutra,
        id: 2,
        stars: 5,
        specifications: [
          "Marca: EGA VAM",
          "Color: Transparente",
          "Volumen de unidad: 280 ml",
          "Formato venta: Unidad",
          "Modelo: Silicona Neutra",
        ],
      },
      {
        name: "Silicona Multiusos Caja x 24",
        price: "180.000",
        pastPrice: "228.000",
        description:
          "Este modelo es de color transparente, lo que lo hace ideal para trabajos que requieran discreción y limpieza en el acabado. Como sellador, su principal componente es la silicona, conocida por su resistencia y durabilidad. Este producto es útil para el sellado de acristalamientos y aplicaciones al aire libre, en soportes no porosos, como vidrio, piezas cerámicas vitrificadas, aluminio, entre otras; Entre otras características, este producto cuenta con propiedades antihongos, lo que lo hace ideal para lugares con alta humedad o propensos a la formación de moho.",
        shortDescription: "Silicona multiusos de alto rendimiento Caja",
        image: siliconaCaja,
        id: 3,
        stars: 5,
        specifications: [
          "Marca: EGA VAM",
          "Color: Transparente",
          "Volumen de unidad: 280 ml",
          "Formato venta: Caja x 24",
          "Modelo: Silicona multiusos",
        ],
      },
      {
        name: "Silicona Neutra Caja x 24",
        price: "228.000",
        pastPrice: "468.000",
        description:
          "Silicona Neutra es un sellador de silicona neutra de alta calidad y excelente adherencia sobre la mayoría de los materiales de construcción, porosos y no porosos ladrillo, concreto, vidrio, aluminio, cerámica, PVC, acrílico, policarbonato y madera, para el uso en interiores y exteriores. La fórmula neutra de este tipo de silicona significa que es menos propensa a causar corrosión en metales o dañar otras superficies sensibles, como el vidrio o piedras naturales, lo que es especialmente importante en la instalación de vidrios templados o laminados.",
        shortDescription: "Silicona neutra para trabajo pesado x 24 Und",
        image: siliconaNeutraCaja,
        id: 4,
        stars: 5,
        specifications: [
          "Marca: EGA VAM",
          "Color: Transparente",
          "Volumen de unidad: 280 ml",
          "Formato venta: Caja x 24",
          "Modelo: Silicona neutra",
        ],
      },
      {
        name: "Kit Acero División Baño",
        price: "135.000",
        pastPrice: null,
        description:
          "Los accesorios en acero inoxidable utilizados para la instalación de divisiones de baño en vidrio templado son elementos clave que combinan funcionalidad, durabilidad y estética. Estos accesorios están diseñados para sostener, asegurar y complementar las mamparas de vidrio templado, proporcionando una solución elegante y segura para baños modernos.  Fabricados en acero inoxidable, estos accesorios son altamente resistentes a la corrosión, lo que es fundamental en ambientes húmedos y propensos a la oxidación como los baños.",
        shortDescription: "Kit para división de baño en acero inoxidable",
        image: kitDiviAcero,
        id: 5,
        stars: 5,
        specifications: [
          "Incuye: Tubo, Carros, Empanadas, Tope, Tiradera, burbuja",
          "Material: Aceri inoxidable",
          "Medida del tubo: Según solicitud del cliente",
          "Diámetro tubo: 1 pulgada",
          "Para vidrio perforado de : 10mm y 16mm",
        ],
      },
      {
        name: "Burbuja/Sello para División Baño 6mm",
        price: "7.000",
        pastPrice: null,
        description:
          "El perfil burbuja en PVC para división de baño en vidrio templado de 6mm, funciona como un tipo de protector de la nave en vidrio, cubriéndola del roce con la pared al cerrar la puerta.  Hecho de PVC (policloruro de vinilo), un material plástico flexible, resistente al agua, al moho y a la mayoría de productos químicos domésticos, lo que lo hace ideal para ambientes húmedos como los baños. Su forma característica de burbuja proporciona un sello eficaz entre el vidrio y el marco o entre dos paneles de vidrio. Este diseño ayuda a amortiguar impactos y a prevenir que el agua se escape, manteniendo el área fuera de la ducha seca.",
        shortDescription:
          "Burbuja sello para division de baño en vidrio de 6mm",
        image: burbujaSeisMM,
        id: 6,
        stars: 5,
        specifications: [
          "Medida: 2.00 m",
          "Uso para tipo de vidrio: 6 mm",
          "Material: plástico",
          "Acabado: Traslúcido",
          "Material: PVC",
        ],
      },
      {
        name: "Vidrio templado Division baño 6mm",
        price: "85.000",
        pastPrice: null,
        description:
          "El vidrio templado para divisiones de baño es una opción popular debido a su resistencia, seguridad y estética. Este tipo de vidrio se somete a un tratamiento térmico que incrementa su resistencia tanto física como térmica, en comparación con el vidrio común. El proceso de templado aumenta significativamente la resistencia del vidrio a los golpes y al estrés térmico. Esto lo hace ideal para áreas susceptibles a cambios de temperatura y uso intensivo, como los baños.",
        shortDescription: "Vidrio templado Division baño 6mm cuerpo MOVIL",
        image: vidrioTemplado,
        id: 9,
        stars: 5,
        specifications: [
          "Grosor: 6mm",
          "Tratamiento térmico: 650°C",
          "Material: Silicio, soda, cal, magnesio",
          "Altura: 180cm y 190cm",
          "Ancho: entre 40cm y 85cm",
        ],
      },
      {
        name: "Vidrio templado Division baño 8mm",
        price: "95.000",
        pastPrice: null,
        description:
          "El vidrio templado para divisiones de baño es una opción popular debido a su resistencia, seguridad y estética. Este tipo de vidrio se somete a un tratamiento térmico que incrementa su resistencia tanto física como térmica, en comparación con el vidrio común. El proceso de templado aumenta significativamente la resistencia del vidrio a los golpes y al estrés térmico. Esto lo hace ideal para áreas susceptibles a cambios de temperatura y uso intensivo, como los baños.",
        shortDescription: "Vidrio templado Division baño 8mm",
        image: vidrioTemplado,
        id: 10,
        stars: 5,
        specifications: [
          "Grosor: 8mm",
          "Tratamiento térmico: 650°C",
          "Material: Silicio, soda, cal, magnesio",
          "Altura: 180cm y 190cm",
          "Ancho: entre 40cm y 85cm",
        ],
      },
    ],
  };
  return productsInfo;
};

export default productsInfoDetails;
