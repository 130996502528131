import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import productsInfoDetails from "../services/productsInfoDetails";

import logo from "../assets/logo.png";
import searchIcon from "../assets/search.svg";

const Header = () => {
  const navigate = useNavigate();

  const [itemsHover, setItemsHover] = useState(0);
  const [foundedProducts, setFoundedProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const foundedProductsRef = useRef(null);

  const productsInfo = productsInfoDetails();

  const menuElements = [
    {
      name: "Inicio",
      id: 1,
      url: "/",
      children: [],
    },
    // {
    //   name: "Productos",
    //   id: 2,
    //   url: "",
    //   children: [
    //     {
    //       name: "Silicona",
    //       url: "",
    //     },
    //     {
    //       name: "Silicona Neutra",
    //       url: "",
    //     },
    //     {
    //       name: "Vidrio templado",
    //       url: "",
    //     },
    //     {
    //       name: "Kit acero inoxidable",
    //       url: "",
    //     },
    //     {
    //       name: "Burbuja",
    //       url: "",
    //     },
    //   ],
    // },
    {
      name: "Contacto",
      id: 3,
      url: "#footer",
      children: [],
    },
  ];

  const handleMouseEnter = (id) => {
    setItemsHover(id);
  };

  const handleMouseLeave = () => {
    setItemsHover(0);
  };

  const handleSearch = (event) => {
    const obtainedSearchTerm = event.target.value;

    let filteredProducts = [];

    if (obtainedSearchTerm !== "") {
      filteredProducts = productsInfo.products.filter((product) =>
        product.name.toLowerCase().includes(obtainedSearchTerm.toLowerCase())
      );
    }

    setFoundedProducts(filteredProducts);
    setSearchTerm(obtainedSearchTerm);
  };

  const handleRouteProduct = (id) => {
    navigate(`/product/${id}`, { replace: true });
    setFoundedProducts([]);
    setSearchTerm("");
  };

  const handleSearchClick = () => {
    let filteredProducts = [];

    if (searchTerm !== "") {
      filteredProducts = productsInfo.products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFoundedProducts(filteredProducts);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        foundedProductsRef.current &&
        !foundedProductsRef.current.contains(event.target)
      ) {
        setFoundedProducts([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [foundedProductsRef]);

  return (
    <div className="px-2 md:px-5 py-2 flex flex-col md:flex-row justify-between items-center border-b-[5px] border-b-[#3089f6]">
      <a href="/" className="w-40">
        <img src={logo} alt="logo" />
      </a>
      <div
        ref={foundedProductsRef}
        className="relative border border-[#cfd9e7] rounded-md py-1 px-2 w-full md:w-[30%] flex items-center mt-3 md:mt-0"
      >
        <input
          type="text"
          name="search-input"
          value={searchTerm}
          className="w-full border-none  outline-none h-8 md:h-6"
          onChange={handleSearch}
        />
        <div
          className="w-7 md:w-5 cursor-pointer"
          onClick={() => handleSearchClick()}
        >
          <img src={searchIcon} alt="search" />
        </div>
        {foundedProducts.length > 0 && (
          <div className="absolute top-[35px] md:top-[25px] -left-[1px] z-50 py-2  bg-white  border border-[#cfd9e7] border-t-0 shadow-xl rounded-b-md w-full">
            {foundedProducts.map((product) => (
              <div
                onClick={() => handleRouteProduct(product.id)}
                className="flex items-center justify-between px-2 py-2 cursor-pointer hover:bg-[#cfd9e7]"
              >
                <span className="text-[15px] md:text-[14px] text-gray-800">
                  {product.name}
                </span>
                <div className="w-[7%] rounded-full border-2 border-[#cfd9e7] hidden md:block ">
                  <img
                    src={product.image}
                    className="rounded-full"
                    alt="This is the product to sale"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="hidden md:flex pr-5">
        {menuElements.map((item, index) => (
          <div
            className="relative"
            key={index}
            onMouseEnter={() => handleMouseEnter(item.id)}
            onMouseLeave={() => handleMouseLeave()}
          >
            <a
              href={item.url}
              className="text-[#3089f6] ml-6 tracking-wider font-semibold"
            >
              {item.name}
            </a>
            {item.children && itemsHover === item.id && (
              <div className="absolute z-50 left-5 bg-gray-100 flex flex-col w-[150px] shadow-md">
                {item.children.map((subItem, index) => (
                  <div
                    index={index}
                    className="hover:bg-[#3089f6] cursor-pointer hover:text-white  text-gray-600"
                  >
                    <a
                      href={subItem.url}
                      className={`text-[12px]  ml-4 tracking-wider font-semibold`}
                    >
                      {subItem.name}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Header;
