import ProductCard from "./ProductCard";
import productsInfoDetails from "../services/productsInfoDetails";
import homeImage from "../assets/home-image.png";

const ContainerProducts = () => {
  const productsInfo = productsInfoDetails();
  return (
    <div className="w-full" id="container-products">
      <div className="hidden md:block relative h-[200px] overflow-hidden shadow-lg border-b-2 border-gray-300">
        <img
          src={homeImage}
          alt="home"
          className="absolute -bottom-[120px] w-full z-0"
        />
      </div>
      <div className="px-5">
        <h1 className="pt-5 text-gray-400 text-xl">Todos los productos</h1>
        <div className="mt-5 pb-5 w-full bg-white grid  grid-cols-1 md:grid-cols-4 gap-x-10 gap-y-5">
          {productsInfo.products.map((product, index) => (
            <ProductCard key={index} product={product} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContainerProducts;
