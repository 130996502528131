import React, { useEffect, useState } from "react";
import StarFull from "../assets/icons/StarFull";

import { useParams } from "react-router-dom";
import productsInfoDetails from "../services/productsInfoDetails";

const ProductInfo = () => {
  const [productSelected, setProductSelected] = useState({});
  const { id } = useParams();

  const productsInfo = productsInfoDetails();

  const renderStars = (quantity) => {
    const stars = [];

    for (let i = 0; i < quantity; i++) {
      stars.push(
        <div key={i} className="mr-1 mt-2">
          <StarFull />
        </div>
      );
    }

    return stars;
  };

  useEffect(() => {
    const filteredProduct = productsInfo.products.filter(
      (product) => product.id === parseInt(id)
    );
    setProductSelected(filteredProduct[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div
      className="pt-5 md:pt-20 flex justify-between px-3 md:px-10 flex-col pb-4"
      id="container-products"
    >
      <div className="w-full flex justify-end text-4xl pb-2 text-[#3089f6] font-bold tracking-wider">
        {productSelected?.name}
      </div>

      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-[35%]">
          <img
            src={productSelected?.image}
            className="rounded-2xl"
            alt="This is the product to sale"
          />
        </div>
        <div className="w-full md:w-[65%] pt-10">
          <div className="font-bold text-3xl  flex flex-col md:flex-row  w-full ">
            Precio:{" "}
            <div className="px-2 flex tracking-wider md:w-[40%] justify-around">
              {productSelected?.pastPrice && (
                <div className="text-gray-500 tracking-wide line-through font-extralight">
                  ${productSelected?.pastPrice}
                </div>
              )}
              {productSelected?.price && (
                <div className="tracking-wide text-red-400">
                  ${productSelected?.price}
                </div>
              )}
            </div>
          </div>

          <p className="mt-5 text-xl text-gray-500 text-justify font-light tracking-wider border rounded-lg px-4 py-2">
            {productSelected?.description}
          </p>
          <div className="flex mt-4 justify-end">
            <div className="flex">
              <span className="font-bold text-3xl text-gray-500 mr-5">
                Score:{" "}
              </span>
              {renderStars(productSelected?.stars)}
            </div>
          </div>
          <div className="border-y-[1px] mt-2 py-3">
            <span className="font-bold text-3xl text-gray-500 mr-5">
              Especificaciones:{" "}
            </span>
            <ul className="list-disc pl-5 text-gray-500 tracking-wide pt-2">
              {productSelected?.specifications?.map((text, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
